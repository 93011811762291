<template>
    <div class="container">
        <div class="tech-secretary-eds-form mt-4">
            <!-- Cert Info Modal -->
            <div class="modal fade" id="certInfoModal" tabindex="-1" aria-labelledby="certInfoModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="certInfoModalLabel">Информация о подписавших</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">
                                <div v-for="(item, index) in certInfos" :key="index">
                                    <p>Шаблон сертификата : {{ item.certTemplate }}</p>

                                    <div v-if="item.bin == ''">
                                        <p>ИИН: {{ item.serialNumber }}</p>
                                        <p>ФИО: {{ item.commonName }}&nbsp;{{ item.givenName }}</p>
                                    </div>
                                    <div v-else>
                                        <p>БИН: {{ item.bin }}</p>
                                        <p>Название организации : {{ item.orgName }}</p>
                                        <p>Роль подписанта : {{ item.roleName }}</p>
                                    </div>

                                    <p>Серийный номер сертификата: {{ item.certificateSerialNumber }}</p>

                                    <p>Срок действия сертификата: {{ item.notBefore }} - {{ item.notAfter }}</p>

                                    <p>Дата подписания : {{ item.time }}</p>

                                    <hr>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->


            <!-- Edit File Modal -->
            <div class="modal fade" id="editFileModal" tabindex="-1" aria-labelledby="editFileModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="editFileModalLabel">Загрузить исправленный документ</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">

                                <div class="form-group row mt-4">
                                    <label class="col-md-3 col-form-label"><b>Загрузите исправленный файл</b></label>
                                    <div class="col-md-9">
                                        <input id="file-upload" type="file" @change="signedFileHandler"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" @click="updateFile(editFileId)">
                                Сохранить
                            </button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->

            <!-- Delete File Modal -->
            <div class="modal fade" id="deleteFileModal" tabindex="-1" aria-labelledby="deleteFileModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="deleteFileModalLabel">Расторжение договора</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">
                                <p>Вы точно хотите отправить запрос на удаление?</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                    @click="deleteConfirmation(deleteFileId)">
                                Отправить
                            </button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Отменить
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->

            <div class="row mb-3">
                <div class="col-md-3">
                    <label for="sort">Сортировка по дате</label>
                    <select class="form-control form-select" id="sort" @input="changeSortStatus">
                        <option v-for="(item, index) in sortDateValues" :key="index"
                                :value="item.status">
                            {{item.name}}
                        </option>
                    </select>
                </div>

                <div class="col-md-3">
                    <label for="filterSignedStatus">Фильтрация по статусу</label>
                    <select class="form-control form-select" id="filterSignedStatus"
                            @input="changeFilterSignedStatus">
                        <option v-for="(item, index) in filterSignedStatuses"
                                :value="item.status"
                                :key="index">{{ item.name }}
                        </option>
                    </select>
                </div>
            </div>


            <div v-if="signatureFilesInfos.length">
                <div class="row">
                    <div class="col-12 ml-3 mt-4">
                        <h2 class="d-block" style="font-size: 1.5rem; font-weight: bold; text-align: center">Список
                            договоров</h2>
                    </div>
                </div>

                <div class="table-responsive-md mt-4">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">Количество подписавших</th>
                            <th scope="col">Название файла</th>
                            <th scope="col">Подписать</th>
                            <th scope="col">Посмотреть информацию</th>
                            <th scope="col">Редактировать</th>
                            <th scope="col">Удалить</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(item, index) in signatureFilesInfos" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{item.count}}</td>
                            <td>
                                <div v-if="item.file">
                                    <a :href="'https://signature.uib.kz/'+item.file.file_url">{{ item.file.file_name
                                        }}</a>
                                </div>
                                <div v-else>Файла нету</div>
                            </td>
                            <td>
                                <button class="btn btn-success" type="button" @click="signFile(item.file_id)"
                                        :class="{ disabled: (signatureFilesInfos[index].count != 0) }">
                                    Подписать
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                                        data-bs-target="#certInfoModal" @click="openInfoModal(item.file_id)"
                                        :class="{ disabled: (signatureFilesInfos[index].count == 0) }">
                                    Посмотреть информация
                                </button>
                            </td>
                            <td>
                                <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                                        data-bs-target="#editFileModal" @click="openEditFileModal(item.file_id)"
                                        :class="{ disabled: (signatureFilesInfos[index].count != 0) }">
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </button>
                            </td>
                            <td>
                                <div v-if="item.file">
                                    <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                                            data-bs-target="#deleteFileModal" @click="openDeleteFileModal(item.file_id)"
                                            v-if="parseInt(item.file.delete_confirmation)===0">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </button>
                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">


                    <Paginate
                            v-model="page"
                            :page-count="pageCount"
                            :click-handler="paginateHandler"
                            :prev-text="'Предыдущий'"
                            :next-text="'Следующий'"
                            :container-class="'pagination'"
                            :page-class="'page-item'">
                    </Paginate>

                </nav>
            </div>

            <h3 class="d-flex justify-content-center" v-else>
                <!--                Договоров пока нет-->
            </h3>


            <!--      <div>-->
            <!--        <nav class="d-flex justify-content-center" aria-label="Page navigation example">-->
            <!--          <ul class="pagination">-->
            <!--            <li class="page-item"><a class="page-link" href="#" @click="prev"><i class="fa fa-arrow-left"-->
            <!--                                                                                 aria-hidden="true"></i></a>-->
            <!--            </li>-->
            <!--            <li class="page-item" v-for="item in pageCount" :key="item">-->
            <!--              <a class="page-link" href="#" @click="()=>movePageNumber(item)">{{ item }}</a>-->
            <!--            </li>-->
            <!--            <li class="page-item"><a class="page-link" href="#" @click="next()"><i class="fa fa-arrow-right"-->
            <!--                                                                                   aria-hidden="true"></i></a>-->
            <!--            </li>-->
            <!--          </ul>-->
            <!--        </nav>-->
            <!--      </div>-->

        </div>
    </div>


</template>

<script>
    import Paginate from '../common/Paginate.vue'
    import axios from "axios";
    import httpClient from "../../services/http.service";
    import {getCookie} from "@/utils/helpers/cookies.helpers";

    export default {
        name: "TechSecretarySignature",
        components: {
            Paginate
        },
        data() {
            return {
                signatureFilesInfos: [],
                certInfos: [],
                signedFile: '',
                editFileId: '',
                deleteFileId: '',
                pageCount: 0,
                loading: false,
                page: +this.$route.query.page || 1,
                signatureFileIndex: -1,
                dateStatus: (+this.$route.query.date === 0 || +this.$route.query.date) ? +this.$route.query.date : 2,
                filterSignedStatus: (+this.$route.query.status === 0 || +this.$route.query.status) ? +this.$route.query.status : 2

            }
        },

        computed: {
            sortDateValues() {
                return [
                    {
                        status: 2,
                        name: 'Без сортировки'
                    },
                    {
                        status: 0,
                        name: 'По убыванию'
                    },
                    {
                        status: 1,
                        name: 'По возрастанию'
                    }
                ]
            },
            filterSignedStatuses() {
                return [
                    {
                        status: 2,
                        name: 'Без фильтрации'
                    },
                    {
                        status: 0,
                        name: 'Не подписанные'
                    },
                    {
                        status: 1,
                        name: 'Подписанные'
                    }
                ]
            }
        },


        methods: {
            async changeSortStatus(e) {
                this.dateStatus = e.target.value
                await this.changePage()
            },
            async changeFilterSignedStatus(e) {
                this.filterSignedStatus = e.target.value
                await this.changePage()
            },
            async paginateHandler(page) {
                this.page = page
                await this.changePage()
            },
            async changePage() {
                this.$router.push(`${this.$route.path}?page=${this.page}&status=${this.filterSignedStatus}&date=${this.dateStatus}`)
                this.signatureFilesInfos = await this.GET_SIGNATURE_FILES(this.page, this.filterSignedStatus, this.dateStatus)
            },

            async openInfoModal(file_id) {
                this.certInfos = await this.GET_INFO_CERT(file_id);
            },
            async openEditFileModal(file_id) {
                this.editFileId = file_id;
                console.log(this.editFileId, "EDIT FILE ID")
            },
            async openDeleteFileModal(file_id) {
                this.deleteFileId = file_id;
                console.log(this.deleteFileId, "DELETE FILE ID")
            },

            async updateFile(file_id) {

                let formData = new FormData();
                formData.append('file', this.signedFile);
                formData.append('file_id', file_id);

                console.log(Object.fromEntries(formData), 'hello from formdata')


                await axios.post('https://signature.uib.kz/updateFile.php',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(async res => {
                    console.log('SUCCESS!!', res)
                    this.$message({title: 'Обновление', text: 'Файл успешно обновлен'})

                }).catch(err => {
                    console.log('FAILURE!!', err)
                    this.$error({title: 'Ошибка при обновлении', text: 'Произошла ошибка при обновлении'})
                });


            },
            async deleteConfirmation(file_id) {

                try {
                    const {
                        status,
                        data
                    } = await httpClient.put('questionnaire/signature-files/delete-confirmation' + '?access-token=' + getCookie('ACCESS_TOKEN') + "&file_id=" + file_id);
                    if (status === 200) {
                        this.$message({title: 'Удаление', text: 'Запрос успешно отправлен'})
                        this.signatureFilesInfos = await this.GET_SIGNATURE_FILES(this.page, this.filterSignedStatus, this.dateStatus)
                    }
                } catch (e) {
                    console.log(e.response);
                    this.$error({
                        title: 'Ошибка при удалении',
                        text: `Произошла ошибка при удалении ID ${file_id} ${e.response.toString()}`
                    })
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async signFile(id) {
                await this.POST_SIGNATURE_FILES(id)
                this.signatureFilesInfos = await this.GET_SIGNATURE_FILES(this.page, this.filterSignedStatus, this.dateStatus)
            },
            async PUT_SIGNATURE_FILES(id) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient.post('questionnaire/signature-files/response-signature' + '?access-token=' + getCookie('ACCESS_TOKEN'), {'file_id': id});
                    if (status === 200) {
                        console.log(data, "PUT SIGNATURE FILES")
                        return data
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async POST_SIGNATURE_FILES(id) {
                try {
                    const {
                        status,
                        data
                    } = await httpClient.post('questionnaire/signature-files/response-signature' + '?access-token=' + getCookie('ACCESS_TOKEN'), {'file_id': id});
                    if (status === 200) {
                        console.log(data, "POST")
                        this.countSignatureFiles = await this.GET_COUNT_SIGNATURE_FILES()
                        this.certInfos = await this.GET_INFO_CERT(this.signatureFilesInfos[0].file_id);
                        return data
                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async GET_SIGNATURE_FILES(page, filterSignedStatus, dateStatus) {
                try {
                    const urlGetParams = `&page=${page}&status=${filterSignedStatus}&date=${dateStatus}`
                    console.log(urlGetParams)
                    const url = 'questionnaire/signature-files/signatured-files-by-user' + '?access-token=' + getCookie('ACCESS_TOKEN') + urlGetParams


                    const {
                        status,
                        data
                    } = await httpClient.get(url);
                    if (status === 200) {
                        console.log(data.success, "SIGN")

                        this.pageCount = data.page_count

                        //let signaturedFiles = data.success

                        //let signaturedFilesCount = await dispatch('GET_COUNT_SIGNATURE_FILES', 40)
                        //console.log(signaturedFilesCount, "signaturedFilesCount")
                        for (let i = 0; i < data.success.length; i++) {

                            let signaturedFilesId = data.success[i].file_id
                            //let signaturedFileCount = await dispatch('GET_COUNT_SIGNATURE_FILES', signaturedFileId)

                            data.success[i]['count'] = await this.GET_COUNT_SIGNATURE_FILES(signaturedFilesId);


                        }

                        return data.success

                    }
                    return {
                        success: true
                    }
                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async GET_INFO_CERT(file_id) {
                try {

                    const {
                        status,
                        data
                    } = await axios.get('https://signature.uib.kz/infoCert.php?file_id=' + file_id);
                    if (status === 200) {
                        console.log(data, "INFOCERT")
                        return data
                    }
                    return {
                        success: true
                    }


                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            async GET_COUNT_SIGNATURE_FILES(file_id) {

                try {

                    const {
                        status,
                        data
                    } = await httpClient.get('questionnaire/signature-files/count-signatured-files' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&file_id=' + file_id);
                    //this.signatureFilesInfos[0].id
                    if (status === 200) {
                        console.log(data.success, "COUNT SIGN")

                        return data.success

                    }
                    return {
                        success: true
                    }

                } catch (e) {
                    console.log(e.response);
                    return {
                        success: false,
                        errors: e.response.data.errors
                    }
                }
            },
            signedFileHandler(e) {
                let file = e.target.files[0];
                this.signedFile = file;
            },
        },

        async mounted() {
            this.signatureFilesInfos = await this.GET_SIGNATURE_FILES(this.page, this.filterSignedStatus, this.dateStatus)
        },
    }
</script>

<style scoped>

</style>